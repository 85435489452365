import * as React from "react"
import Image from 'react-bootstrap/Image'
import logo from "../images/logo-vertical.jpg"


import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Doces D'Arada" />
    <div className="w-50 mx-auto">
      <Image src={logo} alt="Doces D'Arada" style={{ maxHeight: '70vh', maxWidth: '100%' }} />
    </div>
  </Layout>
)

export default IndexPage
